.footer {
  background: transparent;
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 1rem;
}

.link {
  text-decoration: none;
}

p {
  color: azure;
}

a {
  color: rgba(255, 255, 255, 0.6);
}

a:hover {
  color: rgb(255, 174, 0);
}

.github {
  content: url(../Img/github.svg);
  width: 25px;
  height: 25px;
  filter: invert(50%) sepia(25%) saturate(28%) hue-rotate(346deg)
    brightness(90%) contrast(97%);
}
