.main-wrapper {
  height: 100%;
}

.profile-container,
.skills-container,
.work-container {
  position: relative;
}

.profile-wrapper,
.work-wrapper,
.skills-wrapper {
  text-align: left;
  height: fit-content;
  width: 100%;
  padding: 0.3rem 1rem;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-header,
.work-header,
.skills-header {
  color: black;
  font-family: Monsterrat;
}

.profile-divider,
.work-divider,
.skills-divider {
  border-bottom: 1px solid black;
  width: 18%;
  margin-bottom: 0.3rem;
  margin: 0.3rem 0;
}

.work-divider {
  width: 43%;
}

.skills-divider {
  width: 15%;
}

.profile-text,
.work-text,
.skills-text {
  width: 100%;
  height: 100%;
  color: black;
  font-size: 0.6rem;
  font-family: SofiaSans;
}

/* WORK */

.work-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
}

.work-content-wrapper {
  padding-bottom: 0.4rem;
  position: relative;
}

.work-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.5rem;
}

.work-title {
  font-weight: bold;
  color: black;
  font-family: Roboto;
}

.work-span {
  font-style: italic;
  color: grey;
  font-family: Roboto;
}

.work-dates {
  font-size: 0.5rem;
  color: grey;
  font-family: Roboto;
}

.work-icons {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  bottom: 50;
}

/* SKILLS */

.skills-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.skills-content > span {
  justify-self: flex-start;
  padding-bottom: 0.3rem;
  padding-left: 0.1rem;
  color: black;
  font-size: 0.6rem;
  font-weight: 500;
  justify-self: flex-start;
  align-items: center;
  font-family: SofiaSans;
}

.skill-choice-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  font-size: 0.8rem;
  padding: 0.1rem;
  width: 100%;
}

.custom-skill {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.1rem;
}

.custom-skill > input {
  font-size: 0.7rem;
}

/* FORM */

.profile-form,
.work-form,
.skill-form {
  position: absolute;
  border: 1px solid black;
  min-height: 200px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 1;
  bottom: 0;
  right: 0;
  background: radial-gradient(circle at center, #1a1a1a 0%, #000 100%);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.18),
    0px 17px 50px rgba(0, 0, 0, 0.12), 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.profile-form:hover,
.work-form:hover,
.skill-form:hover {
  background: radial-gradient(circle at center, #2e2e2e 0%, #000 100%);
}

.skill-form {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 400px;
}

textarea {
  height: 300px;
  width: 300px;
  color: white;
  margin: 0.5rem;
  resize: none;
  background-color: transparent;
}

#descwork {
  height: 200px;
}

#descwork::placeholder,
textarea::placeholder {
  font-size: 0.8rem;
  text-align: center;
}

.work-form > input {
  width: 250px;
  height: 25px;
  font-size: 0.7rem;
}

.work-form > label {
  padding: 0.2rem 0;
}

/* WORK BUTTONS */

.deleteBtn {
  position: absolute;
  right: 0;
  bottom: 25%;
}

.deleteBtn:hover {
  background-color: #ff4800;
}

.deleteBtn:active {
  top: 37%;
}

.addBtn {
  position: absolute;
  top: 0;
  right: 6%;
}

.addBtn:hover {
  background-color: #08c972;
}
