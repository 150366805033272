.wrapper {
  display: grid;
  grid-template-rows: 1fr 0.1fr 1fr;
  height: 100%;
  width: 100%;
  position: relative;
}

.name-wrapper,
.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 0.6rem;
  font-family: Monsterrat;
}

.title-wrapper {
  align-items: flex-start;
  padding-top: 0.5rem;
  font-size: 0.7rem;
}

.underline {
  border-bottom: 2px solid black;
  width: 20%;
  justify-self: center;
}

input {
  background-color: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  transition: all 0.3s ease-in-out;
}

input:focus {
  border-bottom: 1px solid #ffa700;
}

input[type="file"] {
  border: none;
}

input::placeholder {
  text-align: center;
}

.editBtn {
  position: absolute;
  right: 0;
}

.btn {
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  height: 30px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border-radius: 5px;
  border: none;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffa700;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  background-color: #ff8c00;
  cursor: pointer;
}

.editBtn:hover {
  background-color: #ff8c00;
  cursor: pointer;
}
.editBtn:active {
  top: 2px;
}

.submitBtn {
  height: 30px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border-radius: 5px;
  border: none;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffa700;
  transition: all 0.3s ease-in-out;
}

.submitBtn:hover {
  background-color: #ff8c00;
  cursor: pointer;
}

.name-form {
  position: absolute;
  border: 1px solid black;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  right: 0;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 5px;
  background: radial-gradient(circle at center, #1a1a1a 0%, #000 100%);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.18),
    0px 17px 50px rgba(0, 0, 0, 0.12), 10px 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.name-form:hover {
  background: radial-gradient(circle at center, #2e2e2e 0%, #000 100%);
}

.name-form > label {
  font-size: 0.8rem;
}
