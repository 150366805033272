.profile-picture {
  width: 150px;
  height: 150px;
  background-color: blue;
  border-radius: 100px;
  border: 2px solid white;
  display: flex;
  justify-content: center;
}

.pfp-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pfp-container > input {
  position: absolute;
  width: fit-content;
  left: 0;
}

.card-wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr, 0.5fr, 1fr, 1fr;
  font-size: 0.5rem;
}

.contact-container,
.education-container,
.links-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.contact-wrapper,
.education-wrapper,
.links-wrapper {
  width: 80%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.country,
.telephone,
.email,
.education-title {
  font-family: SofiaSerif;
  font-size: 0.5rem;
  word-break: break-all;
}

.uni,
.dates {
  font-family: Roboto;
}

.education-wrapper {
  padding-bottom: 0.6rem;
}

.card-divider {
  border-bottom: 1px solid white;
  width: 50%;
  margin-bottom: 0.3rem;
}

.card-header {
  font-size: 0.7rem;
  padding-bottom: 0.2rem;
  text-align: left;
  width: 80%;
  position: relative;
  font-family: Monsterrat;
}

.maps-icon,
.phone-icon,
.email-icon,
.add,
.remove {
  width: 20px;
  height: 20px;
}

.uni {
  font-style: italic;
}

.country,
.telephone,
.email {
  display: flex;
  align-items: center;
  gap: 10px;
}

.education-title {
  font-weight: bold;
  font-size: 0.6rem;
}

.contact-form,
.education-form,
.links-form {
  position: absolute;
  border: 1px solid black;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: radial-gradient(circle at center, #1a1a1a 0%, #000 100%);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.18),
    0px 17px 50px rgba(0, 0, 0, 0.12), 10px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 1;
  bottom: 0;
  font-family: SofiaSerif;
}

.contact-form:hover,
.education-form:hover,
.links-form:hover {
  background: radial-gradient(circle at center, #2e2e2e 0%, #000 100%);
}

.education-form {
  height: fit-content;
}

.contact-form > label,
.education-form > label {
  font-size: 0.6rem;
  padding: 0.1rem 0;
}

.add {
  content: url(../Img/add.svg);
  filter: invert(50%) sepia(25%) saturate(28%) hue-rotate(346deg)
    brightness(90%) contrast(97%);
}

.remove {
  content: url(../Img/remove.svg);
  filter: invert(50%) sepia(25%) saturate(28%) hue-rotate(346deg)
    brightness(90%) contrast(97%);
}

.link-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  word-break: break-all;
}

.github-link-icon,
.linkedin-link-icon,
.twitter-link-icon,
.portfolio-link-icon {
  width: 20px;
  height: 20px;
}

.link-wrapper {
  padding-bottom: 0.4rem;
  font-family: SofiaSerif;
}
