* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

:root {
  --card-bg: #253847;
  --name-bg: #c2c1c1;
  --profile-bg: #ffffff;
  --name-text: #a5a5a5;
  --card-text: #f5f5f5;
  font-size: 24px;
}

@font-face {
  font-family: SofiaSans;
  src: url(./Components/Fonts/SofiaSans-VariableFont_wght.ttf);
}

@font-face {
  font-family: Monsterrat;
  src: url(./Components/Fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(./Components/Fonts/Roboto-Regular.ttf);
}

.App {
  text-align: center;
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    circle at center,
    #3b3b3b,
    #2f2f2f,
    #232323,
    #171717,
    #0b0b0b
  );

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cv-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.cv-container {
  width: 595px;
  height: 842px;
  background-color: var(--profile-bg);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 0.8fr 3fr;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.18),
    0px 17px 50px rgba(0, 0, 0, 0.12), 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.card {
  background-color: var(--card-bg);
  grid-area: 1 / 1/ 3/ 2;
}

.name {
  background-color: var(--name-bg);
  margin-left: 10px;
}

.profile {
  background-color: var(--profile-bg);
}

.pdfbutton {
  margin-bottom: 15px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.welcome-message {
  font-size: 0.6rem;
  font-family: SofiaSerif;
  width: 40vw;
  height: fit-content;
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 15px;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.18),
    0px 17px 50px rgba(0, 0, 0, 0.12), 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.welcome-message > span {
  font-family: SofiaSans;
  background: radial-gradient(
    circle,
    rgb(223, 217, 166) 0%,
    rgb(152, 161, 113) 50%,
    rgb(131, 136, 86) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradient 10s ease infinite;
  font-size: 0.7rem;
}

h2 {
  margin: 1rem 0;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgb(244, 220, 185) 0%,
    rgb(222, 222, 150) 50%,
    rgb(200, 180, 128) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradient 10s ease infinite;
  font-size: 1.5rem;
}

.happy {
  font-size: 1.2rem;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgb(153, 235, 29) 0%,
    rgb(185, 225, 8) 75%,
    rgb(205, 232, 6) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradient 10s ease infinite;
  font-family: Roboto;
}

.start-btn {
  font-size: 0.8rem;
  height: 75px;
  width: 100px;
  margin-bottom: 0.2rem;
}

@media (max-width: 768px) {
  .welcome-message {
    width: 70vw;
  }
}

@media (max-width: 555px) {
  .App {
    display: none;
  }
  html {
    background-color: black;
  }
  html::before {
    content: "No support for mobile devices, sorry :)";
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0.2rem;
    text-align: center;
  }
}
